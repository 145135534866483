<template>
  <div class="trainingWrap container">
    <header>
      <headerTop></headerTop>
      <navTab></navTab>
    </header>
    <div class="main-content">
      <el-collapse accordion>
        <el-collapse-item v-for="(item,index) in recordsData" :key="index">
          <template slot="title">
            <i class="header-icon el-icon-question"></i>{{item.title}}
          </template>
          <div v-html='item.content'></div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
import navTab from '@/components/navTab.vue'
import headerTop from '@/components/header.vue'
export default {
  name: '',
  components: {
    footerCom,
    navTab,
    headerTop
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      recordsData: []
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    getContentQueryPage () {
      this.$axios.post(this.$apiUrl.contentQueryPage, { 'params': { 'cmsCategoryId': '1635484563362811905' } }).then(response => {
        let res = response.data
        if (res.success) {
          this.recordsData = res.data.records
          console.log(this.recordsData)
        }
      })
    }
  },
  mounted () {
    this.getUserInfo()
    this.getContentQueryPage()
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 56px;
    /* padding: 0 100px; */

    .header-left-content {
      font-size: 22px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 42px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 34px;
        line-height: 34px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ::v-deep .el-dropdown {
        font-size: 18px;
        color: #4774df;
        background-color: #fff;
        height: 34px;
        line-height: 34px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .nav_side {
    width: 1240px;
    margin: 0 auto;
    height: 61px;
    line-height: 61px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 61px;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      border-bottom: 3px solid #ffffff;
    }
  }
}
  .main-content {
    width: 1240px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding: 20px;
    padding-bottom: 188px;
    background-color: transparent;
    ::v-deep .el-collapse-item__header{
              height: 56px;
              background: #F3FAFF;
              border: 0px;
              border-radius: 6px 6px 6px 6px;
              opacity: 1;
              font-size: 18px;
              font-weight: 400;
              .header-icon{
                width: 26px;
                height: 26px;
                text-align: center;
                line-height: 26px;
                margin: 0 20px;
                color: #25A3FF;
              }
              .el-icon-arrow-right{
                    width: 26px;
                    height: 26px;
                    background: #25A3FF;
                    text-align: center;
                    line-height: 26px;
                    color: #fff;
                    border-radius: 100%;
              }
    }
    ::v-deep .el-collapse-item{
      margin-bottom: 61px
    }
    ::v-deep .el-collapse{
      border: 0px
    }
    ::v-deep .el-icon-arrow-right{

    }
    ::v-deep .el-collapse-item__wrap{
      border-bottom: 0px;
      background-color: transparent;
    }
  }
</style>
